<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'System Settings'">
          <template v-slot:preview>
            <form ref="form">
              <div class="col-md-12 p-0">
                <div class="form-group">
                  <label>Title</label>
                  <input
                    @change="checkValidation()"
                    required
                    v-model="title"
                    class="form-control form-control-solid"
                    placeholder="Enter Title"
                  />
                  <p v-if="error" style="color: red">Please fill this field</p>
                </div>
              </div>
              <div class="col-md-12 p-0">
                <div class="form-group">
                  <label>Terms and Conditions</label>
                  <textarea
                    required
                    @change="checkValidation()"
                    v-model="terms"
                    class="form-control form-control-solid"
                    placeholder="Enter Terms & Conditions Here..."
                    rows="15"
                  ></textarea>
                  <p v-if="error" style="color: red">Please fill this field</p>
                </div>
              </div>
              <div class="col-md-12 p-0">
                <div class="form-group">
                  <label>Privacy and Policy</label>
                  <textarea
                    required
                    @change="checkValidation()"
                    v-model="privacy"
                    class="form-control form-control-solid"
                    placeholder="Enter Privacy & Policy Here..."
                    rows="15"
                  ></textarea>
                  <p v-if="error" style="color: red">Please fill this field</p>
                </div>
                <div class="text-right">
                  <button
                    :disabled="error"
                    type="button"
                    @click="AddSettings"
                    class="btn custom-btn-style"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
import ApiService from "@/core/services/api.service";
import helpers from "../../helpers";
import axios from "axios";

export default {
  data() {
    return {
      error: false,
      title: "",
      terms: "",
      privacy: "",
      settings: []
    };
  },
  components: {
    KTCodePreview
  },
  methods: {
    getSettings() {
      axios
        .get(this.$path + "/admin/setting")
        .then(response => {
          this.title = response.data.data.title;
          this.terms = response.data.data.termsandcondition;
          this.privacy = response.data.data.privacyandpolicy;
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    AddSettings() {
      this.loader = true;
      let obj = {
        title: this.title,
        termsandcondition: this.terms,
        privacyandpolicy: this.privacy
      };
      ApiService.post(this.$path + "/admin/setting", obj)
        .then(response => {
          this.loader = false;
          this.title = response.data.data.title;
          this.terms = response.data.data.termsandcondition;
          this.privacy = response.data.data.privacyandpolicy;
          helpers.successMessages(this, response.data.message);
        })
        .catch(err => {
          this.loader = false;
          if (err.response.data.status === 400) {
            helpers.errorOther(this, err.response.data);
          }
          if (err.response.data.status === 422) {
            helpers.validationError(this, err.response.data.errors);
          }
          if (err.response.status === 404) {
            helpers.errorMessage(this, "url not found");
          }
          if (err.response.status === 500) {
            helpers.serverError(this);
          }
        });
    },
    checkValidation() {
      if (this.title !== "" && this.terms !== "" && this.privacy !== "") {
        this.error = false;
      } else {
        this.error = true;
      }
    }
  },
  mounted() {
    this.getSettings();
  }
};
</script>
